
export default {
    props: {
        text: {
            type: String,
            default: "Click Me",
        },
        url: {
            type: String,
            default: "/",
        },
    },
}
